import Vue from 'vue'
import VueRouter from 'vue-router'
// import 
Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'login',
  component: () => import("@/views/login/login.vue"),
  beforeEnter: (to, from, next) => {
    if (localStorage.getItem('access_token')) {
      next('/layerPage/user')
    } else {
      next()
    }
  }
}, {
  path: '/layerPage',
  name: 'layerPage',
  component: () => import("@/components/layer/layer.vue"),
  meta: {
    name: '主页',
    path: "layerPage"
  },
  children: [{
    name: 'user',
    path: 'user',
    component: () => import('../views/user/user.vue'),
    meta: {
      name: '会员管理',
      auth: true,
      showInfo: true,
      munuName: 'user', //控制左边菜单
    }
  }, {
    name: 'list',
    path: 'list',
    component: () => import('../views/user/list.vue'),
    meta: {
      name: '确权列表',
      auth: true,
      showInfo: true,
      munuName: 'user', //控制左边菜单
    }
  }, {
    name: 'whiteList',
    path: 'whiteList',
    component: () => import('../views/user/whiteList.vue'),
    meta: {
      name: '白名单',
      auth: true,
      showInfo: true,
      munuName: 'user', //控制左边菜单
    }
  }, {
    name: 'orderList',
    path: 'orderList',
    component: () => import('../views/user/orderList.vue'),
    meta: {
      name: '订单列表',
      auth: true,
      showInfo: true,
      munuName: 'user', //控制左边菜单
    }
  }]
}]
const router = new VueRouter({
  routes
})
export default router
