let base_url;
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    // base_url = 'http://112.74.181.197:9092/admin/'; //测试地址
    // base_url = 'http://192.168.1.32:9092/admin/'; //测试地址
    base_url = 'https://api.sccpy.com/admin/'; //正式地址
} else {
    base_url = 'https://api.sccpy.com/admin/'; //正式地址
    // base_url = 'http://112.74.181.197:9092/admin/'; //测试地址
    // base_url = 'http://192.168.1.32:9092/admin/'; //测试地址
}
export { base_url }