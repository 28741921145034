<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
};
</script>

<style  lang="less">
@import "./assets/css/common.less";

* {
  margin: 0;
  padding: 0;
}

body,
html {
  width: 100%;
  min-height: 100vh;
}

p {
  margin: 0%;
}
// 隐藏全局滚动条
// ::-webkit-scrollbar{
//     display: none;
// }
</style>
