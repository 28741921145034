import axios from 'axios'
// import qs from 'qs'
// 引入接口地址
import { base_url } from './config'
import router from '../router/index'
//自动切换环境
// axios.defaults.baseURL = base_url
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: base_url,
    // baseURL: process.env === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_TEST_API,
    // 超时，注意！axios的超时是中断请求，即canceled，非timeout，具体参见http://www.axios-js.com/zh-cn/docs/#axios-create-config
    timeout: 15000,
    retryDelay: 600
})
// 请求拦截
service.interceptors.request.use(
    (config) => {
        let token = window.localStorage.getItem('access_token')
        if (token) {
            config.headers['token'] = token
        }
        if (config.method === 'post' || config.method === 'delete') {
            config.headers['Content-Type'] = 'application/json'
        }
        if (config.url == '/file/save') {
            config.headers['Content-Type'] = 'application/form-data'
        }
        return config
    },
    (err) => {
        return Promise.reject(err)
    }
)
/* 返回状态判断(添加响应拦截器) */
service.interceptors.response.use((res) => {
    /* 对返回数据是否成功做判断 */
    return res.data
}, (error) => {
    if (error.response.status == 401) {
        localStorage.clear();
        router.push('/')
    }
    return Promise.reject(error)
})

// 封装get方法
/* 返回一个Promise(发送get请求) 采用query格式*/
export function axiosGet(url, param) {
    return new Promise((resolve, reject) => {
        service.get(url, { params: param })
            .then(response => {
                resolve(response)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

//封装post方法
export function axiosPost(url, params, headers) {
    return new Promise((resolve, reject) => {
        service.post(url, params, headers).then(response => {
            resolve(response)
        }, err => {
            reject(err)
        }).catch(error => {
            reject(error)
        })
    })
}
// 封装delete方法
export function axiosDelete(url, params) {
    return new Promise((resolve, reject) => {
        service.delete(url, { data: params }).then(response => {
            resolve(response)
        }, err => {
            reject(err)
        }).catch(error => {
            reject(error)
        })
    })
}
// 封装put方法
export function axiosPut(url, params) {
    return new Promise((resolve, reject) => {
        service.put(url, params).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err.data)
        })
    })
}

